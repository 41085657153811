export const facts = [
  `If the Earth was shrunk down to the size of a bowling ball, it'd be too small`,
  `A pig's orgasm lasts for 30 minutes. Don't believe me? Try it yourself!`,
  `The average honey bee is over 3lbs. Beware the mega-bee!`,
  `Lions can hold their breath for 30 minutes, just not all at once.`,
  `Baseball was invented by Roy Baseball and was originally called soccer.`,
  `Most people have the ability to talk to ghosts, they just can't hear the response.`,
  `Before child labor laws, children used to have manners.`,
  `A deck of cards originally had over 700 cards but that number was cut down because it was too difficult to shuffle.`,
  `The first Iron Man Marathon was performed accidentally by a very scared man and the bear who was chasing him.`,
  `To research his role "Saving Private Ryan" Tom Hanks went back in time to kill Nazis.`,
  `Pizza gets its shape from the plant that shares its name.`,
  `Octopuses can type 90 words per minute.`,
  `Most people die on Thursday.`,
  `The age of the universe can be determined by asking.`,
  `To honor the first Olympics, the first Scrabble tournament was played naked.`,
  `Left handed people are more likely to push on "pull" doors.`,
  `Mermaids are colorblind.`,
  `If you read a book per week for 10 years, you'd read over 30 books!`,
  `The largest ruby ever discovered was found over there.`,
  `Blue whales can't tell the difference between "there", "their" and "they're".`,
  `Charles Darwin chose to study finches because he was a nerd.`,
  `Before the invention of toilets, people just held it.`,
  `Killer whales are named for their killer attitudes.`,
  `The fitted sheet was invented by Jesus as punishment for our sins.`,
  `The big and little hands on the clock are named for their inventors: Alfred Big and Johnathan Little.`,
  `Pizza is rarely found in the wild.`,
  `During a disaster, planes are grounded so that the prayers keeping them aloft can be redirected elsewhere.`,
  `Bill Gates inherited his company from William Microsoft during an office tour that resulted in the deaths of six other tour members.`,
  `Moss always grows on some sides of the tree.`,
  `The brake and gas pedal do the same thing, but in different directions.`,
  `Mice like solving mazes for the fun of it. The cheese at the end is coincidental.`,
  `George Washington Carver published an extensive collection of erotica.`,
  `Dolphins are the most judgmental of all aquatic mammals`,
  `There are actually only nine birth signs, three of them are repeats.`,
  `The Devil's horns are actually antennae which he uses to detect predators and air currents.`,
  `Male zebras are black with white stripes, while females are the reverse.`,
  `Owls can't detect sarcasm.`,
  `Unicorn horns are made of the same material as your fingernails.`,
  `Babies weigh less in the womb because they are buoyant.`,
  `Crabs believe in aliens.`,
  `Snakes evolved upside down.`,
  `Electric razors work by scaring the hair away.`,
  `There are over three types of turtles.`,
  `Mickey mouse is based on a real mouse Walt Disney knew in college.`,
  `Dinosaurs were so large it took them over forty minutes to brush their teeth.`,
  `Although most animals can smell fear, only sloths can smell sexual arousal.`,
  `Most types of magical elements are extinct except for fire and electricity.`,
  `Although many attempts were made, vikings never landed on the moon.`,
  `Skeletons are made of smaller skeletons called skeletrinos.`,
];
